@use '../../styles/mixins' as mixins;
@use '../../styles/breakpoints' as bp;
	
.sustainable-investors-block {

	@include mixins.sectionStyles;

	.sustainable-investors-wrapper {
		display: grid;
		grid-template-columns: repeat(8, 1fr);
		gap: 1rem;

		@media screen and (max-width: bp.$breakpoint-medium) {
			grid-template-columns: repeat(6, 1fr);
		}

		@media screen and (max-width: bp.$breakpoint-extra-small) {
			grid-template-columns: repeat(4, 1fr);
		}

		@media screen and (max-width: bp.$breakpoint-tiny) {
			grid-template-columns: repeat(2, 1fr);
		}
		
		.sustainable-investors-block__title {
			grid-column: 1 / span 3;

			@media screen and (max-width: bp.$breakpoint-small) {

				grid-column: 1 / span 4;

				&+div {
					display: none;
				}
			}


			@media screen and (max-width: bp.$breakpoint-extra-small) {

				grid-column: 1 / span 2;

			}
			
			.bnpp-pill {
				display: inline-block;
				background: var(--wp--preset--color--bnpp-green-tint-30);
				border-radius: 50px;
				padding: 0.2rem var(--wp--preset--spacing--40);
				font-weight: 300;
				cursor: pointer;
				transition: 300ms;
			}
		}

		.bnpp-sustainable-investors-grid-item {
			grid-column: span 2;

			&:nth-child(7n + 5) {
				grid-column: 2 / span 2;

				@media screen and (max-width: bp.$breakpoint-medium) {
					grid-column: span 2;
				}
			}
		}
	}

}